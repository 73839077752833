<template>
	<el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true"
		:destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
		<div class="vue-box">
			<!-- 参数栏 -->
			<div class="c-panel">
				<el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
					label-width="120px">
			
					<el-form-item label="配件名称：" prop="name">
						<el-input v-model="m.name"></el-input>
					</el-form-item>
					<el-form-item label="规格：" prop="sku">
						<el-input v-model="m.sku"></el-input>
					</el-form-item>
					<el-form-item label="价格：" prop="price">
						<el-input v-model="m.price"></el-input>
					</el-form-item>
					<el-form-item label="单位：" prop="unit">
						<el-input v-model="m.unit"></el-input>
					</el-form-item>
					<el-form-item label="数量：" prop="num">
						<el-input v-model="m.num"></el-input>
					</el-form-item>
					<el-form-item label="损耗量：" prop="lossNum">
						<el-input v-model="m.lossNum"></el-input>
					</el-form-item>
					<el-form-item label="产品图片">
						<el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
							:data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_image"
							:before-remove="remove_image" :file-list="m.imageFile">
							<el-button size="mini" type="primary">点击上传</el-button>
							<div slot="tip" class="el-upload__tip">上传产品图片</div>
						</el-upload>
					</el-form-item>
					<el-form-item>
						<span class="c-label">&emsp;</span>
						<el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	//import inputEnum from "../../sa-resources/com-view/input-enum.vue";
	export default {
		//components: { inputEnum },
		props: ["params"],
		data() {
			return {
				m: {},
				title: "",
				isShow: false,
				rules: {
					name: [{
						required: true,
						message: '请输入物料id',
						trigger: 'blur'
					}, ],
					sku: [],
					price: [],
					unit: [],
					num: [],
					lossNum: [],
					image: [],
				},
				fileList: [],
			}
		},
		methods: {
			open: function(data) {
				this.isShow = true;
				if (data.id) {
					this.title = "修改 物料配件表";
					data.imageFile = JSON.parse(data.image);
					this.m = data;
				} else {
					this.m = {
						materialId: data.materialId,
						name: '',
						sku: '',
						price: 0,
						unit: '',
						num: 0,
						lossNum: 0,
						image: '',
						imageFile:[]
					}
					this.title = "添加 物料配件表";
				}
			},
			success_image(response, file, fileList) {
				if (response.code != 200) {
					this.sa.error(response.message);
					return;
				}
				if (!this.m.imageFile) {
					this.m.imageFile = [];
				}
				this.m.imageFile.push(response.data);
				console.log(fileList);
			},
			remove_image(file, fileList) {
				this.m.imageFile = fileList;
			},

			//提交物料配件表信息
			ok: function(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.m.image = JSON.stringify(this.m.imageFile.map(item => {
							let a = {};
							a.name = item.name;
							a.url = item.url;
							return a;
						}));
						this.sa.post("/materialPartner/save", this.m).then((res) => {
							console.log(res);
							this.$parent.f5();
							this.isShow = false;
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			}
		},
		created() {},
	};
</script>
